<template>
  <div class="cmp-product-list-item utlz-col-sm-4" :class="'utlz-col-lg-' + blockSize">
    <div class="utlz-row utlz-no-gutters utlz-gutters-sm item-wrapper">
      <template v-if="product.prices != 'undefined'">
        <span v-if="isSalesAction" v-translation="{ type: 'label', code: 'label_sales_action' }" class="action-banner"></span>
      </template>
      <div class="utlz-col-3 utlz-col-sm-12 img-container">

        <!-- BESPOKE EDEX-40: Disabled favorites -->
        <!-- <utlz-favorites-control v-if="showFavorites" :product="product"></utlz-favorites-control> -->
        <!-- END BESPOKE EDEX-40 -->
        <!-- BESPOKE EDEX: Changed image banners to a seperate control, should be in a future release -->
        <template v-if="screenWidth >= 576">
          <utlz-product-banners :banners="product.productImageBanners"></utlz-product-banners>
        </template>
        <!-- END BESPOKE EDEX -->

        <figure class="img-square prod-img">
          <a :href="product.targetUrl" class="clickthrough">
            <img class="img-fluid" :alt="product.imageAltText" :title="product.imageTitleText" :src="product.imageUrl ? product.imageUrl : '/documents/productimages/not-available-large.jpg'" />
          </a>
        </figure>
      </div>

      <div class="utlz-col-9 utlz-col-sm-12 text-container">
        <a :href="product.targetUrl" class="clickthrough">

          <!-- BESPOKE EDEX: Changed image banners to a seperate control, should be in a future release -->
          <template v-if="screenWidth <= 576">
            <utlz-product-banners :banners="product.productImageBanners"></utlz-product-banners>
          </template>
          <!-- END BESPOKE EDEX -->

          <div class="product-code">
            <span class="value">{{ product.id }}</span>
          </div>
          <h2 class="prod-title header-small header-normal-sm">{{ product.shortDescription }}</h2>
        </a>

        <!-- BESPOKE EDEX-46: Disabled product price in product blocks -->
        <!-- <div class="ph-product-price" v-if="product.prices.length">
          <utlz-product-price
            :prices="product.prices[0]"
            :parentClassRef="'ref-product-list-item'"
          ></utlz-product-price>
        </div> -->
        <!-- END BESPOKE EDEX-46 -->

        <utlz-product-variant v-if="product.variantKey && groupProductsList != 0 && showOrderProductControl"
                              :product="computedProduct"
                              :productVariants="product.productVariants"
                              @selectedProduct="selectedProduct($event)"></utlz-product-variant>

        <div class="ph-order-product" :class="{ 'hidden': !userLoggedOn }">
          <transition name="fade">
            <utlz-order-product v-if="showOrderProductControl"
                                :product="computedProduct"
                                :parentClassRef="'ref-product-list-item'">
            </utlz-order-product>
          </transition>

          <transition name="fade">
            <p v-if="showOutOfStockMessage && showStock" class="out-of-stock-msg">
              <i class="fa fa-warning"></i>
              <span v-translation="{ type: 'label', code: 'label_out_of_stock' }"></span>
            </p>
          </transition>

          <!-- BESPOKE EDEX: Added stock loading icon -->
          <div v-if="showStock && product.stock.stockTotal === null" class="stock-loader-wrapper">
            <span class="loader"></span>
            <span v-translation="{ type: 'label', code: 'label_retrieving_stock' }"></span>
          </div>
          <!-- END BESPOKE EDEX -->
        </div>

        <!-- If product has variants, disable stock and
        use stock in OrderProduct.vue component which is variant specific -->
        <!-- BESPOKE EDEX-40: Disable stock -->
        <!-- <utlz-product-stock
          v-if="!product.variantKey || groupProductsList == 0"
          :stock="product.stock"
          :stockTotal="product.stock"
        ></utlz-product-stock> -->
        <!-- END BESPOKE EDEX-40 -->
      </div>

    </div>
  </div>
</template>

<script>
  import { mapState, mapGetters, mapActions } from 'vuex';
  import { Product } from 'src/models/product.model';
  import OrderProduct from 'src/components/webshop/order-product/OrderProduct.vue';
  import ProductStock from 'src/components/webshop/product-stock/ProductStock.vue';
  import ProductPrice from 'src/components/webshop/product-price/ProductPrice.vue';
  import ProductVariant from 'src/components/webshop/products/product-variant/ProductVariant.vue';
  import FavoritesControl from 'src/components/webshop/favorites/FavoritesControl.vue';
  // BESPOKE
  // Added product banners control, which should be in a future release
  import ProductBanners from 'src/components/webshop/banners/ProductBanners.vue';
  // END BESPOKE
  export default {
    components: {
      'utlz-product-price': ProductPrice,
      'utlz-product-stock': ProductStock,
      'utlz-order-product': OrderProduct,
      'utlz-product-variant': ProductVariant,
      'utlz-favorites-control': FavoritesControl,
      // BESPOKE
      // Added product banners control, which should be in a future release
      'utlz-product-banners': ProductBanners
      // END BESPOKE
    },
    props: {
      product: { type: Product, required: true },
      blockSize: { type: Number, required: false, default: 3 }
    },
    data() {
      return {
        stockProduct: this.product.customBooleans.STOCK_PRODUCT,
        isSalesAction: this.product.prices[0],
        activeProductVariantId: null
      };
    },
    computed: {
      ...mapState('elastic', ['layoutType']),
      // BESPOKE EDEX
      // Added userLoggedOn
      ...mapGetters(['showStock', 'showPrices', 'showFavorites', 'showOrderProduct', 'stockLimit', 'groupProductsList', 'screenWidth', 'condenseProductList', 'userLoggedOn']),
      // END BESPOKE EDEX
      showOrderProductControl() {
        if (this.showOrderProduct) {
          if (!this.stockLimit) {
            return true;
          } else if (this.stockLimit && this.product.stock.stockTotal !== null) {
            return this.stockLimit && this.product.stock.stockTotal > 0;
          } else {
            return false;
          }
        } else {
          return false;
        }
      },
      showOutOfStockMessage() {
        if (!this.stockLimit || !this.stockProduct) {
          return false;
        } else if (this.product.stock.stockTotal !== null && this.showOrderProduct) {
          return this.product.stock.stockTotal <= 0;
        } else {
          return false;
        }
      },
      computedProduct() {
        if (!this.stockLimit || !this.stockProduct) {
          return this.product;
        } else {
          if (this.activeProductVariantId) {
            const index = this.product.productVariants.findIndex(product => {
              return product.id === this.activeProductVariantId;
            });
            return this.product.productVariants[index];
          } else {
            return this.product;
          }
        }
      },
      productImageBanners() {
        return this.product.productImageBanners.filter(banner => banner.show === true);
      }
    },
    methods: {
      selectedProduct(id) {
        this.activeProductVariantId = id;
      },
      // Changed emitted event to action that sends data to the dataLayer before redirecting
      ...mapActions('elastic', ['pushProductClickToDataLayer']),
      navigateToProductPage() {
        return this.pushProductClickToDataLayer(this.product.id);
      }
    }
  };
</script>

<style>
</style>
