import axios from 'axios';
import { Product } from 'src/models/product.model';

const state = {
  searchText: '',
  autocompleteData: [],
  productsFound: false,
  searchConfig: {
    UserId: null,
    Category: null,
    StringProperties: null,
    NumericProperties: null,
    StringAggregations: null,
    NumericAggregations: null,
    ExtraQueries: []
  },
  selectedProduct: {}
};

const getters = {
  searchText(state) {
    return state.searchText;
  },
  searchConfig(state) {
    return {
      UserId: null,
      Category: state.searchConfig.Category,
      StringProperties: null,
      NumericProperties: null,
      StringAggregations: state.searchConfig.StringAggregations,
      NumericAggregations: null,
      ExtraQueries: state.searchConfig.ExtraQueries,
      ExcludeLabels: state.searchConfig.ExcludeLabels,
      ExcludeCategories: state.searchConfig.ExcludeCategories,
      IncludeLabels: state.searchConfig.IncludeLabels,
      ShouldIncludeLabels: state.searchConfig.ShouldIncludeLabels
    }
  },
  elasticProductsEndpoint(state, getters, rootState, rootGetters) {
    const endpoint = rootGetters.elasticProductsEndpoint;
    const from = 0;
    const language = rootGetters.language;
    const size = 10;
    const aggregations = false;
    const pricingQueryString = '';
    const customerListId = '';
    const query = state.searchText;
    const customerId = rootGetters.userLoggedOn ? rootGetters.customerId : '';
    const client = rootGetters.clientCode;
    const priceListCode = rootGetters.customerPriceList;
    const groupProducts = false;
    const sort = null;
    const sortingType = '';
    var sortingstring = ''

    if (sort !== null) sortingstring = '&sort=' + sort + '|' + sortingType;

    return `${endpoint}/GetProductCodeAndDesc?lang=${language}${sortingstring}&from=${from}&size=${size}&aggr=${aggregations}&query=${query}&customerId=${customerId}&customerPricelist=${priceListCode}&listId=${customerListId}&client=${client}&pricingQuerystring=${pricingQueryString}&groupProducts=${groupProducts}`;
  },
  productInformationEndpoint(state, getters, rootState, rootGetters) {
    const endpoint = rootGetters.productInformationEndpoint;
    const client = rootGetters.clientCode;
    const language = rootGetters.language;

    return `${endpoint}/${client}?language=${language}`;
  },
  productsFound(state) {
    return state.productsFound;
  },
  autocompleteData(state) {
    return state.autocompleteData;
  }
};

const mutations = {
  setSearchText(state, payload) {
    state.searchText = payload;
  },
  setAutoCompleteData(state, payload) {
    state.autocompleteData = payload;
  },
  initSearchConfig(state, searchConfig) {
    state.searchConfig = { ...searchConfig };
  },
  productsFound(state, payload) {
    state.productsFound = payload;
    if (!payload) {
      state.selectedProduct = [];
    }
  },
  setSelectedProduct(state, payload) {
    state.selectedProduct = payload;
  },
  setProductPrice(state, productWithPrice) {
    state.selectedProduct = productWithPrice;
  },
  setProductStock(state, productWithStock) {
    state.selectedProduct = productWithStock;
  }
};

const actions = {
  getAutocompleteData({ commit, getters, dispatch, rootGetters, state }) {
    if (getters.searchText !== '') {
      axios.post(getters.elasticProductsEndpoint, getters.searchConfig)
        .then(res => {
          if (res.data) {
            commit('setAutoCompleteData', res.data);
            commit('productsFound', true);
          } else {
            commit('productsFound', false);
          }
        })
        .catch(error => {
          commit('productsFound', false);
        });
    } else {
      commit('setAutoCompleteData', []);
      commit('productsFound', false);
    }
  },
  clearSelectedProduct({ commit }) {
    commit('productsFound', false);
    commit('setAutoCompleteData', []);
  },
  getProductInformation({ commit, getters, dispatch, rootGetters }) {
    let acData = getters.autocompleteData;

    if (acData.filter((x) => x.key === getters.searchText).length > 0) {
      let mainProductCodes = [getters.searchText];

      axios.post(getters.productInformationEndpoint, mainProductCodes)
        .then(res => {
          if (res.data.length) {
            const products = res.data.map(product => new Product(product));

            if (rootGetters.showStock) {
              dispatch('getProductStock', products);
            } else {
              products.forEach(product => { product.setStock({ stockTotal: 0 }); });
            }

            if (rootGetters.showPrices) {
              dispatch('getProductPrices', products);
            }

            commit('setSelectedProduct', products[0]);

            if (products[0].customBooleans.NOT_AVAILABLE_PROD) {
              dispatch('getReplacements', products[0].id)
            }
          }
          commit('setSearchText', '');
          commit('setAutoCompleteData', []);
          commit('productsFound', false);
        });
    }
  },
  getProductStock({ commit, rootGetters, dispatch }, products) {
    products.forEach(product => {
      axios.post(rootGetters.productStockEndpoint, { 'productCode': product.id })
        .then(res => {
          product.setStock(res.data.d);
          commit('setProductStock', product);
        });
    });
  },
  getProductPrices({ commit, getters, dispatch, rootGetters }, products) {
    let priceRequestWrapper = {};
    priceRequestWrapper.CustomerId = rootGetters.userLoggedOn ? rootGetters.customerId : '';
    priceRequestWrapper.Pricelist = rootGetters.customerPriceList;
    priceRequestWrapper.Products = products.map(prod => {
      let ret = {};
      ret.ProductId = prod.id;
      ret.ProductGroup = prod.discountGroup;

      ret.ProductUnit = '';
      if (prod.units !== undefined && prod.units !== null) {
        ret.ProductUnit = prod.units.length > 0 ? prod.units[0].code : '';
      }
      return ret;
    });

    let endpoint = rootGetters.productPriceEndpoint + 'prices/' + rootGetters.clientCode

    axios.post(endpoint, priceRequestWrapper)
      .then(res => {
        res.data.forEach(price => {
          let product = products[products.findIndex(x => x.id === price.productId)];

          const pricesObj = [];
          Object.keys(price.volumes).forEach(key => {
            pricesObj.push({
              price: price.volumes[key].price,
              basePrice: price.volumes[key].basePrice,
              isSalesAction: price.volumes[key].isSalesAction,
              quantity: Math.round(parseInt(key))
            });
          });

          product.setPrices(pricesObj);
          commit('setProductPrice', product);
        });
      });
  },
  updateSearchText({ commit, getters, dispatch, rootGetters }, payload) {
    commit('setSearchText', payload)
  }
};

export default {
  namespaced: true,
  state: state,
  getters: getters,
  actions: actions,
  mutations: mutations
};
