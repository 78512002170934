<template>
  <div class="cmp-elastic-order-entry rows utlz-reset">

    <h1 v-translation="{ type:'title', code: 'title_order_entry' }" class="header-25"></h1>

    <utlz-searchbar id="searchbar" :placeholder="placeholder" v-model="searchText" />

    <transition-group v-if="selectedProduct !== null" name="fade" tag="div" class="list-items-wrapper utlz-row">
      <utlz-product-list-item :key="selectedProduct.id"
                              :product="selectedProduct"
                              :blockSize="3"
                              @orderButtonClick="handleOrderButtonClick"
                              class="order-entry-item">
      </utlz-product-list-item>
    </transition-group>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import Searchbar from 'src/components/webshop/search-bar/Searchbar.vue';
import ProductListItem from 'src/components/webshop/products/product-list-item/ProductListItem.vue';
export default {
  data () {
    return {
      placeholder: window.vue.translations.label.label_placeholder_order_entry,
      searchText: ''
    }
  },
  methods: {
    ...mapActions('orderEntry', ['clearSelectedProduct']),
    handleOrderButtonClick () {
      this.searchText = '';
      this.clearSelectedProduct();
    }
  },
  components: {
    'utlz-searchbar': Searchbar,
    'utlz-product-list-item': ProductListItem
  },
  computed: {
    ...mapState('orderEntry', ['selectedProduct'])
  }
}
</script>

<style>
</style>
