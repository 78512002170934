import { formatPrice } from 'src/utilities/helpers';

export class OrderLine {
  constructor (orderLine) {
    this.product = null;
    this.shoppingCartId = orderLine.ShoppingCartId;
    this.productId = orderLine.ProductId;
    this.quantity = parseInt(orderLine.Quantity);
    this.computedQuantity = null;
    this.totalOrderLinesQuantity = orderLine.TotalCount;
    this.rawQuantity = parseInt(orderLine.Quantity);
    this.unitCode = orderLine.UnitCode;
    this.isFree = orderLine.IsFree;
    this.lineId = orderLine.LineId;
    this.comments = orderLine.Comments;
    this.exceedsStock = false;
    this.totals = {
      price: '',
      rawPrice: 0
    };
  }

  // BESPOKE EDEX
  // Added method to retrieve orderline units
  getUnitDescription (unitCode) {
    return this.product.units.filter(unit => unit.code === unitCode)[0];
  }
  // END BESPOKE EDEX

  setProduct (product) {
    this.product = product;
  }

  getComputedQuantity () {
    let quantity = this.quantity;
    if (this.product.units) {
      quantity = this.product.units.filter(unit => unit.code === this.unitCode)[0].quantity * this.quantity;
    } else if (this.product.saleUnit > 1) {
      quantity = this.product.saleUnit * this.quantity;
    }
    return quantity;
  }

  setComputedQuantity () {
    this.computedQuantity = this.getComputedQuantity();
  }

  setOrderLineTotal () {
    let price, totals;
    const quantity = this.getComputedQuantity();
    if (this.product.prices[0].isSalesAction) {
      price = this.product.prices[0].rawBasePrice;
    } else {
      price = this.product.prices[0].rawPrice;
    }
    totals = price * quantity;
    this.totals = {
      price: formatPrice(totals),
      rawPrice: totals
    };
  }
}
